import * as React from 'react'
import cx from 'classnames'
import {graphql} from 'gatsby'
import {InlineSVG} from './InlineSVG'

export const query = graphql`
  fragment Picture on ContentfulAsset {
    id
    title
    file {
      url
      contentType
      details {
        image {
          width
          height
        }
      }
    }
  }
`

export interface PictureData {
  id: string
  title?: string
  file: {
    url: string
    contentType: string
    details: {
      image: {
        width: number
        height: number
      }
    }
  }
}

export interface PictureProps {
  fixed?: boolean
  data: PictureData
  quality?: number
  alt?: string
  maxWidth?: number
  maxHeight?: number
  width?: number
  height?: number
  className?: string
  imgClassName?: string
}

// Hardcoded asset ids for the Swedish and English versions of the download
// buttons. It would be nice to instead add some attribute on the images
// in Contenful, but there doesn't seem to be a way to extends the Media
// content with e.g. a new field.
const DOWNLOAD_BUTTON_IMAGES = new Set([
  'c9cb60be-c53b-5af4-b2b0-081e3a77f8ab',
  'f0d496d1-a3ce-5f51-a69d-45a6eee0b0ce',
  '1a4db400-082d-58b7-83f2-e59c780b8c87',
  '3f629e30-f3dd-5b82-a6be-2652e8a02c48',
])

export const Picture: React.FC<PictureProps> = props => {
  const {data, alt, className, quality: q = 50} = props
  const {title, file} = data
  const {
    url,
    details: {image},
  } = file
  const aspectRatio = image.height / image.width
  const width = props.width ?? image.width
  const height = props.height ?? Math.round(width * aspectRatio)

  if (DOWNLOAD_BUTTON_IMAGES.has(data.id)) {
    return (
      <InlineSVG
        url={url}
        alt={alt ?? title}
        className={cx(className, 'has-active-effect')}
        width={width}
        height={height}
      />
    )
  }
  if (file.contentType === 'image/svg+xml') {
    return (
      <picture className={className}>
        <img
          loading="lazy"
          src={url}
          alt={alt ?? title}
          title={alt ?? title}
          width={width}
          height={height}
          className={props.imgClassName}
        />
      </picture>
    )
  }
  return (
    <picture className={className}>
      <source
        type="image/webp"
        srcSet={getSrcSet(url, image.width, {q, fm: 'webp'})}
        sizes="(max-width: 500px) 50vw, 100vw"
      />
      <img
        loading="lazy"
        srcSet={getSrcSet(url, image.width, {q})}
        sizes="(max-width: 500px) 50vw, 900px"
        src={getImgUrl(url, {q, w: width})}
        alt={alt ?? title}
        title={alt ?? title}
        width={width}
        height={height}
        className={props.imgClassName}
      />
    </picture>
  )
}

interface ImageModifiers {
  fm?: 'webp' | 'jpg' | 'png'
  q?: number
  w?: number
  r?: number
  bg?: string
}

const getImgUrl = (url: string, modifiers: ImageModifiers = {}) => {
  const query = Object.entries(modifiers)
    .filter(([value]) => !!value)
    .map(([name, value]) => `${name}=${value}`)
    .join('&')
  return `https:${url}?${query}`
}

const getSrcSet = (url: string, width: number, modifiers: ImageModifiers = {}) =>
  [1, 0.9, 0.8, 0.7, 0.6, 0.5, 0.4, 0.3, 0.2, 0.1]
    .map(percent => Math.round(width * percent))
    .filter(w => width <= 500 || w >= 500)
    .map(w => `${getImgUrl(url, {...modifiers, w})} ${w}w`)
    .join(', ')
