import * as React from 'react'
import {useStaticQuery, graphql} from 'gatsby'

import {ContentfulComponentSwitch} from '../ContentfulComponentSwitch'

interface LayoutFooterContentfulDownloadIconsProps {
  locale: string
}

export const LayoutFooterContentfulDownloadIcons: React.FC<LayoutFooterContentfulDownloadIconsProps> = ({
  locale,
}) => {
  // Note: useStaticQuery does not accept variables, so we fetch each locale
  // separately, rather than passing the locale as a parameter.
  const data = useStaticQuery(
    graphql`
      query {
        en: contentfulColumnGroup(
          contentful_id: {eq: "6D4TrXAjS7cyzihr6FbHhX"}
          node_locale: {eq: "en"}
        ) {
          ...ContentfulColumnGroup
        }
        sv: contentfulColumnGroup(
          contentful_id: {eq: "6D4TrXAjS7cyzihr6FbHhX"}
          node_locale: {eq: "sv"}
        ) {
          ...ContentfulColumnGroup
        }
      }
    `
  )
  return (
    <div className="columns is-mobile is-centered is-flex-wrap-wrap">
      {data[locale]?.columns?.map((column, i) => (
        <span key={i} className="column is-two-thirds-mobile">
          <ContentfulComponentSwitch data={column} />
        </span>
      ))}
    </div>
  )
}
