import * as React from 'react'
import {graphql, Link} from 'gatsby'

export const query = graphql`
  fragment CookieConsent on ContentfulSiteConfig {
    cookieConsentMessage
    cookieConsentAgreeButton
    privacyPolicyPage {
      title
      path
    }
  }
`

export interface CookieConsentData {
  cookieConsentMessage: string
  cookieConsentAgreeButton: string
  privacyPolicyPage: {
    title: string
    path: string
  }
}

export interface CookieConsentProps {
  data: CookieConsentData
  locale: string
  onPreferenceChange: (cookies: {analytics: boolean; intercom: boolean}) => void
}

export const CookieConsent: React.FC<CookieConsentProps> = ({data, locale, onPreferenceChange}) => {
  const [analytics, setAnalytics] = React.useState(true)
  const [intercom, setIntercom] = React.useState(true)
  const [waited, setWaited] = React.useState(false)

  React.useEffect(() => {
    const handle = window.setTimeout(() => {
      setWaited(true)
    }, 500)
    return () => clearTimeout(handle)
  }, [])

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    onPreferenceChange({analytics, intercom})
  }

  if (waited) {
    return (
      <div className="cookie-consent py-4">
        <form className="container is-size-6 px-4" onSubmit={handleSubmit}>
          <div className="columns is-vcentered">
            <p className="column">
              {data.cookieConsentMessage}{' '}
              <Link to={`/${locale}/${data.privacyPolicyPage.path}/`}>
                {data.privacyPolicyPage.title}
              </Link>
            </p>
            <div className="column is-narrow">
              <span className="field mr-2">
                <input
                  id="analytics"
                  type="checkbox"
                  className="switch is-rounded is-outlined is-info"
                  checked={analytics}
                  onChange={e => setAnalytics(e.target.checked)}
                />
                <label htmlFor="analytics">Analytics</label>
              </span>
              <span className="field">
                <input
                  id="intercom"
                  type="checkbox"
                  className="switch is-rounded is-outlined is-info"
                  checked={intercom}
                  onChange={e => setIntercom(e.target.checked)}
                />
                <label htmlFor="intercom">Support</label>
              </span>
            </div>
            <div className="column is-narrow">
              <button type="submit" className="button is-info is-fullwidth">
                {data.cookieConsentAgreeButton}
              </button>
            </div>
          </div>
        </form>
      </div>
    )
  } else {
    return null
  }
}
