import * as React from 'react'
import {graphql} from 'gatsby'
import {ContentfulCopy, ContentfulCopyData} from '../ContentfulCopy'
import {Picture, PictureData} from '../Picture'

export const query = graphql`
  fragment ContentfulImageWithText on ContentfulImageWithText {
    type: __typename
    id
    image {
      ...Picture
    }
    text {
      ...ContentfulCopy
    }
  }
`

export interface ContentfulImageWithTextData {
  type: 'ContentfulImageWithText'
  id: string
  image: PictureData
  text: ContentfulCopyData
}

export interface ContentfulImageWithTextProps {
  data: ContentfulImageWithTextData
}

export const ContentfulImageWithText: React.FC<ContentfulImageWithTextProps> = ({data}) => {
  const {image, text} = data
  return (
    <div
      className={'columns is-gapless'}
      style={{
        marginBottom: '3rem',
      }}>
      <div key={image.id} className={'column is-one-third'}>
        <div className={'columns is-mobile is-centered'}>
          <div className={'column is-two-thirds-mobile'}>
            <Picture data={image} className="image" />
          </div>
        </div>
      </div>
      <div className={'column'}>
        <ContentfulCopy data={text} />
      </div>
    </div>
  )
}
