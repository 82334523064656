import * as React from 'react'
import cx from 'classnames'

export type TheJourneyProps = React.HTMLAttributes<HTMLDivElement>

export const TheJourney: React.FC<TheJourneyProps> = ({children, className, ...rest}) => {
  return (
    <div className="container is-narrow pb-0">
      <div className={cx('the-journey', className)} {...rest}>
        {children}
      </div>
    </div>
  )
}
