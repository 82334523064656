import * as React from 'react'
import {graphql} from 'gatsby'
import {Picture, PictureData} from '../Picture'

export const query = graphql`
  fragment ContentfulPerson on ContentfulPerson {
    type: __typename
    id
    name
    jobTitle
    linkedInUrl
    image {
      ...Picture
    }
  }
`

export interface ContentfulPersonData {
  type: 'ContentfulPerson'
  id: string
  name: string
  jobTitle: string
  linkedInUrl: string
  image: PictureData
}

export interface ContentfulPersonProps {
  data: ContentfulPersonData
}

export const ContentfulPerson: React.FC<ContentfulPersonProps> = ({data}) => {
  const {name, jobTitle, image} = data
  return (
    <div className="content has-text-centered is-text-small mb-6">
      <Picture data={image} width={256} className="image" imgClassName="is-rounded" />
      <h5 className="has-text-elsa-red mt-2 mb-0">{name}</h5>
      <p className="mt-0">{jobTitle}</p>
    </div>
  )
}
