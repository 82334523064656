import * as React from 'react'
import {Link as GatsbyLink} from 'gatsby'
import cx from 'classnames'
import {ContentfulCopy, ContentfulCopyData} from '../ContentfulCopy'
import {Link, LinkCollection} from './Link'
import {LayoutFooterContentfulDownloadIcons} from './LayoutFooterContentfulDownloadIcons'

const locales = [
  ['sv', 'Svenska'],
  ['en', 'English'],
]

interface LayoutFooterProps {
  locale: string
  navMenu: LinkCollection
  footerLinks: LinkCollection[]
  footerCopy: ContentfulCopyData
}

export const LayoutFooter: React.FC<LayoutFooterProps> = ({
  locale,
  navMenu,
  footerLinks,
  footerCopy,
}) => (
  <footer className="footer section has-background-blue fit-content-to-bottom is-text-small">
    <div className="container">
      <div className="columns is-mobile is-align-items-center pl-3">
        <div className="mr-5">
          <img src={`/ce-logo.svg`} width={40} alt="CE" title="CE" />
        </div>
        <div>
          <img src={`/badge-gdpr.svg`} width={100} alt="GDPR compliant" title="GDPR compliant" />
        </div>
      </div>

      <div className="columns is-multiline is-tablet">
        <div className="column is-5">
          <ContentfulCopy data={footerCopy} />
        </div>

        <div className="column is-offset-1 is-6 right">
          <LayoutFooterContentfulDownloadIcons locale={locale} />

          <div className="columns is-mobile has-text-weight-bold">
            <div className="column">
              <ul>
                {navMenu.links.map(item => (
                  <li key={item.id}>
                    <Link key={item.id} activeClassName="is-active" locale={locale} item={item} />
                  </li>
                ))}
                <li>&nbsp;</li>
              </ul>

              <ul>
                {locales.map(([id, name]) => (
                  <li key={id}>
                    <GatsbyLink
                      className={cx({
                        'is-active': id === locale,
                      })}
                      to={`/${id}/`}>
                      {name}
                    </GatsbyLink>
                  </li>
                ))}
              </ul>
              {footerLinks.map(({id, links}) => (
                <ul key={id} className="mb-3">
                  {links.slice(4, 7).map(item => (
                    <li key={item.id}>
                      <Link locale={locale} item={item} />
                    </li>
                  ))}
                </ul>
              ))}
            </div>

            <div className="column">
              {footerLinks.map(({id, links}) => (
                <ul key={id} className="mb-3">
                  {links.slice(0, 4).map(item => (
                    <li key={item.id}>
                      <Link locale={locale} item={item} />
                    </li>
                  ))}
                </ul>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
)
