import * as React from 'react'
import {Picture, PictureData} from '../../Picture'

export interface ScreenContentImageProps {
  image: PictureData
}

export const ScreenContentImage: React.FC<ScreenContentImageProps> = ({image}) => (
  <div className="carousel">
    <Picture key={image.id} data={image} className="carousel-cell" />
  </div>
)
