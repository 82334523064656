import * as React from 'react'
import {Link as GatsbyLink} from 'gatsby'
import cx from 'classnames'
import {Picture, PictureData} from '../Picture'
import {LinkCollection, Link} from './Link'

interface LayoutHeaderProps {
  locale: string
  logo: PictureData
  headerCssClasses?: string
  alternatePagePath?: string
  headerMenu: LinkCollection
}

export const LayoutHeader: React.FC<LayoutHeaderProps> = ({
  locale,
  logo,
  alternatePagePath,
  headerMenu,
}) => {
  const [burgerMenuActive, setBurgerMenuActive] = React.useState(false)
  const [scrollPosition, setScrollPosition] = React.useState(0)
  const toggleBurgerMenu = () => setBurgerMenuActive(!burgerMenuActive)
  const SCROLL_THRESHOLD = 20
  const isTop = scrollPosition < SCROLL_THRESHOLD

  const onScroll = React.useCallback(() => {
    setScrollPosition(window.pageYOffset)
  }, [])

  React.useLayoutEffect(() => {
    onScroll()
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [onScroll])

  return (
    <nav
      className={cx('navbar', 'is-fixed-top', 'has-text-weight-medium', 'is-text-small', {
        'is-top': isTop,
      })}
      role="navigation"
      aria-label="main navigation">
      <div className="container is-fluid">
        <div className="navbar-brand">
          <GatsbyLink to={`/${locale}/`} className="navbar-item">
            <Picture data={logo} width={138} />
          </GatsbyLink>
          <a
            role="button"
            className={cx('navbar-burger', {'is-active': burgerMenuActive})}
            aria-label="menu"
            aria-expanded={burgerMenuActive ? 'true' : 'false'}
            data-target="navbarMenu"
            onClick={toggleBurgerMenu}>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div id="navbarMenu" className={cx('navbar-menu', {'is-active': burgerMenuActive})}>
          <div className="navbar-end has-text-weight-bold mr-4">
            {headerMenu.links.map(item => (
              <Link
                key={item.id}
                className="navbar-item mr-1"
                activeClassName="is-active"
                locale={locale}
                item={item}
              />
            ))}
            <GatsbyLink
              className="navbar-item"
              to={alternatePagePath ?? `/${locale === 'en' ? 'sv' : 'en'}/`}>
              {locale === 'en' ? 'Svenska' : 'English'}
            </GatsbyLink>
          </div>
        </div>
      </div>
    </nav>
  )
}
