import * as React from 'react'
import {graphql} from 'gatsby'
import {ContentfulTestimonial, ContentfulTestimonialData} from '../ContentfulTestimonial'
import Flickity, {FlickityOptions} from 'react-flickity-component'
import {ArrowButton} from '../ArrowButton'

export const query = graphql`
  fragment ContentfulCarousel on ContentfulCarousel {
    type: __typename
    id
    items {
      ...ContentfulTestimonial
    }
  }
`
export interface ContentfulCarouselData {
  type: 'ContentfulCarousel'
  id: string
  items: ContentfulTestimonialData[]
}

export interface ContentfulCarouselProps {
  data: ContentfulCarouselData
}

export const ContentfulCarousel: React.FC<ContentfulCarouselProps> = ({data}) => {
  const {items} = data
  const flickityRef = React.useRef<Flickity>(null)

  const flickityOptions: FlickityOptions = {
    initialIndex: 1,
    pageDots: false,
    prevNextButtons: false,
    groupCells: 1, // 1 works better for desktop and mobile.
    wrapAround: true,
    cellAlign: 'left',
    adaptiveHeight: false,
    imagesLoaded: true,
    setGallerySize: false,
  }

  const handleNext = () => flickityRef.current.next()
  const handlePrev = () => flickityRef.current.previous()

  return (
    <>
      <Flickity
        flickityRef={c => (flickityRef.current = c)}
        className="contentful carousel"
        elementType={'div'}
        options={flickityOptions}
        disableImagesLoaded={false}
        static>
        {items.map((item: ContentfulTestimonialData) => (
          <ContentfulTestimonial key={item.id} data={item} />
        ))}
      </Flickity>
      <div className="columns is-mobile is-centered my-2">
        <ArrowButton
          title="previous"
          handleOnClick={handlePrev}
          arrowStyle="has-small-chevron-left"
        />
        <ArrowButton title="next" handleOnClick={handleNext} arrowStyle="has-small-chevron-right" />
      </div>
    </>
  )
}
