import * as React from 'react'
import {graphql} from 'gatsby'
import {renderRichText} from '../ContentfulRichText'
import {Picture, PictureData} from '../Picture'

export const query = graphql`
  fragment ContentfulTestimonial on ContentfulTestimonial {
    type: __typename
    locale: node_locale
    id
    author {
      name
      jobTitle
      image {
        ...Picture
      }
    }
    body {
      raw
    }
  }
`

export interface ContentfulTestimonialData {
  type: 'ContentfulTestimonial'
  locale: string
  id: string
  author: {
    name: string
    jobTitle: string
    image: PictureData
  }
  body: {
    raw: string
  }
}

export interface ContentfulTestimonialProps {
  data: ContentfulTestimonialData
}

export const ContentfulTestimonial: React.FC<ContentfulTestimonialProps> = ({data}) => {
  const {locale, body, author} = data

  return (
    <div className="carousel-cell">
      <div className="speech-bubble content">{renderRichText({locale, richText: body.raw})}</div>
      <div className="columns is-mobile is-vcentered mt-5">
        <Picture data={author.image} className="column is-narrow py-0 pr-0" width={100} />
        <div className="column author-info py-0">
          <p className="has-text-weight-bold">
            <b>{author.name}</b>
          </p>
          {author.jobTitle && <p>{author.jobTitle}</p>}
        </div>
      </div>
    </div>
  )
}
