import * as React from 'react'
import {graphql} from 'gatsby'
import cx from 'classnames'
import {ContentfulCopyData} from '../ContentfulCopy'
import {ContentfulPersonData} from '../ContentfulPerson'
import {ContentfulComponentSwitch} from '../ContentfulComponentSwitch'

export const query = graphql`
  fragment ContentfulColumnGroup on ContentfulColumnGroup {
    type: __typename
    id
    columns {
      ...ContentfulPerson
      ...ContentfulLink
    }
    rowStyles
    columnStyles
  }
`

export interface ContentfulColumnGroupData {
  type: 'ContentfulColumnGroup'
  id: string
  columns: (ContentfulCopyData | ContentfulPersonData)[]
  rowStyles?: string
  columnStyles?: string
}

export interface ContentfulColumnGroupProps {
  data: ContentfulColumnGroupData
  className?: string
}

export const ContentfulColumnGroup: React.FC<ContentfulColumnGroupProps> = ({data, className}) => {
  const {columns, rowStyles, columnStyles} = data
  return (
    <div className={cx('columns', className, rowStyles)}>
      {columns.map(column => (
        <div key={column.id} className={cx('column', columnStyles)}>
          <ContentfulComponentSwitch data={column} />
        </div>
      ))}
    </div>
  )
}
