import React, {ReactElement} from 'react'

interface ContentfulTextWithLinkProps {
  title: string
  buttonLabel: string
  url: string
}

export const ContentfulTextWithLink = ({
  title,
  buttonLabel,
  url,
}: ContentfulTextWithLinkProps): ReactElement => (
  <div className="text-with-link has-button-links">
    <h4>{title}</h4>
    <a href={url} rel="noopener noreferrer" target="_blank">
      {buttonLabel}
    </a>
  </div>
)
