import * as React from 'react'
import {graphql} from 'gatsby'
import {Picture, PictureData} from '../Picture'
import cx from 'classnames'

export const query = graphql`
  fragment ContentfulImageGroup on ContentfulImageGroup {
    type: __typename
    id
    images {
      ...Picture
    }
    rowStyles
    columnStyles
  }
`

export interface ContentfulImageGroupData {
  type: 'ContentfulImageGroup'
  id: string
  images: PictureData[]
  rowStyles?: string
  columnStyles?: string
}

export interface ContentfulImageGroupProps {
  data: ContentfulImageGroupData
}

export const ContentfulImageGroup: React.FC<ContentfulImageGroupProps> = ({data}) => {
  const {images, rowStyles, columnStyles} = data
  return (
    <div className={cx('columns', rowStyles)}>
      {images.map(image => (
        <div key={image.id} className={cx('column', columnStyles)}>
          <Picture data={image} className="image" />
        </div>
      ))}
    </div>
  )
}
