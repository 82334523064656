import * as React from 'react'

export interface SVGProps {
  url: string
  alt: string
  width?: number
  height?: number
  className?: string
}

export const InlineSVG: React.FC<SVGProps> = ({url, alt, className, width, height}) => {
  const [svg, setSvg] = React.useState<string | null>(null)
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    fetch(url)
      .then(res => res.text())
      .then(setSvg)
      .finally(() => setLoading(false))
  }, [url])

  if (loading || !svg) {
    return <div className={className}>{alt}</div>
  }
  return (
    <div
      style={{maxWidth: width, maxHeight: height}}
      className={className}
      dangerouslySetInnerHTML={{__html: svg}}
    />
  )
}
