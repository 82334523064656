import * as React from 'react'
import Flickity, {FlickityOptions} from 'react-flickity-component'
import {ArrowButton} from '../../ArrowButton'
import {Picture, PictureData} from '../../Picture'

export interface ScreenContentCarouselProps {
  images: PictureData[]
}

export const ScreenContentCarousel: React.FC<ScreenContentCarouselProps> = ({images}) => {
  const flickityRef = React.useRef<Flickity>(null)

  const flickityOptions: FlickityOptions = {
    initialIndex: 0,
    pageDots: false,
    prevNextButtons: false,
    groupCells: 1, // 1 works better for desktop and mobile.
    wrapAround: true,
    cellAlign: 'center',
    adaptiveHeight: true,
    imagesLoaded: false,
    contain: true,
  }

  const handleNext = () => flickityRef.current.next()
  const handlePrev = () => flickityRef.current.previous()

  return (
    <>
      <Flickity
        flickityRef={c => (flickityRef.current = c)}
        className="carousel"
        elementType={'div'}
        options={flickityOptions}
        disableImagesLoaded={false}
        static>
        {images.map(image => (
          <Picture key={image.id} data={image} className="carousel-cell" width={100} height={100} />
        ))}
      </Flickity>
      <div className="carousel-arrows columns is-centered is-mobile">
        <ArrowButton
          title="previous"
          handleOnClick={handlePrev}
          arrowStyle="has-small-chevron-left"
        />
        <ArrowButton title="next" handleOnClick={handleNext} arrowStyle="has-small-chevron-right" />
      </div>
    </>
  )
}
