import * as React from 'react'
import {Device} from './Device'
// import cx from 'classnames'
import {PictureData} from '../Picture'

export interface JourneySegmentProps extends React.HTMLAttributes<HTMLDivElement> {
  images: PictureData[]
}

// export const JourneySegment: React.FC<JourneySegmentProps> = ({
//   images,
//   children,
//   className,
//   ...rest
// }) => {
//   return (
//     <figure className={cx('segment container is-narrow', className)} {...rest}>
//       <Device images={images} />
//       <figcaption>{children}</figcaption>
//     </figure>
//   )
// }

export const JourneySegment: React.FC<JourneySegmentProps> = ({images, children}) => {
  return (
    <figure className="segment">
      <div className="columns is-vcentered">
        <div className="column is-narrow">
          <Device images={images} />
        </div>
        <figcaption className="column">{children}</figcaption>
      </div>
    </figure>
  )
}
