import * as React from 'react'

export interface ArrowButtonProps {
  handleOnClick: (e: any) => void
  title: string
  arrowStyle: string
}

export const ArrowButton: React.FC<ArrowButtonProps> = ({handleOnClick, arrowStyle, title}) => {
  return (
    <button onClick={handleOnClick} title={title} className="circular-button">
      <p className={arrowStyle} />
    </button>
  )
}
