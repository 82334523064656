import * as React from 'react'
import {graphql} from 'gatsby'
import {renderRichText, RichTextReference} from '../ContentfulRichText'
import cx from 'classnames'

export const query = graphql`
  fragment ContentfulCopy on ContentfulCopy {
    type: __typename
    locale: node_locale
    id
    contentful_id
    body {
      raw
      references {
        __typename
        ... on ContentfulAsset {
          contentful_id
          ...Picture
        }
        ... on ContentfulPage {
          contentful_id
          path
        }
        ... on ContentfulTextWithLink {
          contentful_id
          title
          buttonLabel
          url
        }
      }
    }
    cssClasses
  }
`

export interface ContentfulCopyData {
  type: 'ContentfulCopy'
  id: string
  contentful_id: string
  locale: string
  body: {
    raw: string
    references: RichTextReference[]
  }
  cssClasses: string
}

export interface CopyProps {
  data: ContentfulCopyData
}

export const ContentfulCopy: React.FC<CopyProps> = ({data}) => {
  const {locale, body, cssClasses} = data
  return (
    <div className={cx('content', cssClasses)}>
      {renderRichText({
        locale,
        richText: body.raw,
        references: body.references,
      })}
    </div>
  )
}
