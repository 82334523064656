import * as React from 'react'
import {documentToReactComponents, Options} from '@contentful/rich-text-react-renderer'
import {Document, BLOCKS, INLINES} from '@contentful/rich-text-types'
import {Link} from 'gatsby'
import {Picture, PictureData} from '../Picture'
import AudioPlayer from 'react-h5-audio-player'
import cx from 'classnames'
import {LayoutFooterContentfulDownloadIcons} from '../Layout/LayoutFooterContentfulDownloadIcons'
import {ContentfulTextWithLink} from '../ContentfulTextWithLink'

export type RichTextReference =
  | {
      __typename: 'ContentfulPage'
      path: string
      contentful_id: string
    }
  | ({
      __typename: 'ContentfulAsset'
      contentful_id: string
    } & PictureData)
  | {
      __typename: 'ContentfulBlogPostDownloadCta'
      contentful_id: string
      text: {
        raw: string
      }
    }
  | {
      __typename: 'ContentfulTextWithLink'
      contentful_id: string
      title: string
      buttonLabel: string
      url: string
    }

export const renderRichText = ({
  locale,
  richText,
  references = [],
}: {
  locale: string
  richText: string
  references?: RichTextReference[]
}): React.ReactNode => {
  const options: Options = {
    renderText: text =>
      text
        .split('\n')
        .reduce(
          (children, textSegment, index) => [
            ...children,
            index > 0 && <br key={index} />,
            textSegment,
          ],
          []
        ),
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        const handleClick = () => {
          node.data.uri.match(/https:\/\/www.elsa.science\/get\/(ios|android)/) &&
            window['gtag']?.('event', 'get_app')
        }
        return (
          <a href={node.data.uri} rel="noopener noreferrer" target="_blank" onClick={handleClick}>
            {children}
          </a>
        )
      },
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        const reference = references.find(ref => ref.contentful_id === node.data.target.sys.id)
        if (reference && reference.__typename === 'ContentfulPage') {
          return <Link to={`/${locale}/${reference.path}/`}>{children}</Link>
        }
        return children
      },
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const reference = references.find(ref => ref.contentful_id === node.data.target.sys.id)
        if (reference.__typename === 'ContentfulAsset') {
          if (reference.file.contentType.match(/^image\//)) {
            return (
              <p>
                <Picture
                  className={cx({
                    image: reference.file.details.image.width >= 1000,
                  })}
                  data={reference}
                />
              </p>
            )
          } else if (reference.file.contentType.match(/^audio\//)) {
            return <AudioPlayer src={`https:${reference.file.url}`} />
          }
        }
        return null
      },
      [BLOCKS.EMBEDDED_ENTRY]: node => {
        const reference = references.find(ref => ref.contentful_id === node.data.target.sys.id)
        if (reference && reference.__typename === 'ContentfulBlogPostDownloadCta') {
          return (
            <div className="blogpost-download-app">
              <p>{renderRichText({locale, richText: reference.text.raw, references})}</p>
              <LayoutFooterContentfulDownloadIcons locale={locale} />
            </div>
          )
        }
        return null
      },
      [BLOCKS.EMBEDDED_ENTRY]: node => {
        const reference = references.find(ref => ref.contentful_id === node.data.target.sys.id)
        if (reference && reference.__typename === 'ContentfulTextWithLink') {
          const {title, buttonLabel, url} = reference
          return <ContentfulTextWithLink title={title} buttonLabel={buttonLabel} url={url} />
        }
        return null
      },
    },
  }
  return <>{documentToReactComponents(JSON.parse(richText) as Document, options)}</>
}
