import * as React from 'react'
import {Link as GatsbyLink} from 'gatsby'

export interface InternalLink {
  type: 'ContentfulPage'
  id: string
  title: string
  headerLabel?: string
  path: string | null
}

export interface ExternalLink {
  type: 'ContentfulLink'
  id: string
  title: string
  url: string
}

export interface Separator {
  type: 'ContentfulSeparator'
}

export interface LinkCollection {
  id: string
  links: (InternalLink | ExternalLink)[]
}

interface LinkProps {
  item: InternalLink | ExternalLink | Separator
  locale: string
  className?: string
  activeClassName?: string
}

export const Link: React.FC<LinkProps> = ({item, locale, className, activeClassName}) => {
  switch (item.type) {
    case 'ContentfulLink': {
      return (
        <a className={className} href={item.url} rel="noopener noreferrer" target="_blank">
          {item.title}
        </a>
      )
    }
    case 'ContentfulPage': {
      const path = [locale, item.path].filter(slug => !!slug).join('/')
      return (
        <GatsbyLink className={className} activeClassName={activeClassName} to={`/${path}/`}>
          {item.headerLabel ?? item.title}
        </GatsbyLink>
      )
    }
    case 'ContentfulSeparator': {
      return <span>&nbsp;</span>
    }
  }
}
