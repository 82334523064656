import * as React from 'react'
import {ContentfulCopy, ContentfulCopyData} from './ContentfulCopy'
import {ContentfulColumnGroup, ContentfulColumnGroupData} from './ContentfulColumnGroup'
import {ContentfulImageWithText, ContentfulImageWithTextData} from './ContentfulImageWithText'
import {ContentfulPerson, ContentfulPersonData} from './ContentfulPerson'
import {ContentfulImageGroup, ContentfulImageGroupData} from './ContentfulImageGroup'
import {ContentfulComposition, ContentfulCompositionData} from './ContentfulComposition'
import {ContentfulLink, ContentfulLinkData} from './ContentfulLink'
import {ContentfulCarousel, ContentfulCarouselData} from './ContentfulCarousel'
import {ContentfulJourney, ContentfulJourneyData} from './ContentfulJourney'

export type ContentfulComponentUnion =
  | ContentfulCopyData
  | ContentfulColumnGroupData
  | ContentfulPersonData
  | ContentfulImageGroupData
  | ContentfulImageWithTextData
  | ContentfulCompositionData
  | ContentfulLinkData
  | ContentfulCarouselData
  | ContentfulJourneyData

export interface ContentfulComponentSwitchProps {
  data: ContentfulComponentUnion
}

export const ContentfulComponentSwitch: React.FC<ContentfulComponentSwitchProps> = ({data}) => {
  switch (data.type) {
    case 'ContentfulColumnGroup':
      return <ContentfulColumnGroup data={data} />
    case 'ContentfulCopy':
      return <ContentfulCopy data={data} />
    case 'ContentfulPerson':
      return <ContentfulPerson data={data} />
    case 'ContentfulImageGroup':
      return <ContentfulImageGroup data={data} />
    case 'ContentfulImageWithText':
      return <ContentfulImageWithText data={data} />
    case 'ContentfulComposition':
      return <ContentfulComposition data={data} />
    case 'ContentfulLink':
      return <ContentfulLink data={data} />
    case 'ContentfulCarousel':
      return <ContentfulCarousel data={data} />
    case 'ContentfulJourney':
      return <ContentfulJourney data={data} />
    default:
      return null
  }
}
