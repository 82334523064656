import * as React from 'react'
import {graphql} from 'gatsby'
import {Helmet} from 'react-helmet'
import {CookieConsent, CookieConsentData} from './CookieConsent'
import {ContentfulCopyData} from '../ContentfulCopy'
import {useCookies} from 'react-cookie'
import {CookieSetOptions} from 'universal-cookie'
import {PictureData} from '../Picture'
import {globalHistory} from '@reach/router'
import {LayoutHeader} from './LayoutHeader'
import {LinkCollection} from './Link'
import {LayoutFooter} from './LayoutFooter'
import cx from 'classnames'
import type {ContentfulPageData} from '../ContentfulPage'

export const query = graphql`
  fragment SiteConfig on ContentfulSiteConfig {
    id
    siteName
    locale: node_locale
    logo {
      ...Picture
    }
    headerMenu {
      ...LayoutLinkCollection
    }
    footerMenus {
      ...LayoutLinkCollection
    }
    footerCopy {
      ...ContentfulCopy
    }
    ...CookieConsent
  }

  fragment LayoutLinkCollection on ContentfulLinkCollection {
    id
    links {
      type: __typename
      ... on ContentfulLink {
        id
        title
        url
      }
      ... on ContentfulPage {
        id
        title
        headerLabel
        path
      }
      ... on ContentfulSeparator {
        id
      }
    }
  }
`

export interface SiteConfigData extends CookieConsentData {
  siteName: string
  defaultDescription: string
  locale: string
  logo: PictureData
  headerMenu: LinkCollection
  footerMenus: LinkCollection[]
  footerCopy: ContentfulCopyData
}
export interface LayoutProps {
  isDark?: boolean
  isPreview?: boolean
  isAmp?: boolean
  data: SiteConfigData
  alternatePagePath?: string
  headerCssClasses?: string
  hubSpotTrackingCode?: ContentfulPageData['hubSpotTrackingCode']
}

export const Layout: React.FC<LayoutProps> = ({
  isDark,
  data,
  children,
  alternatePagePath,
  headerCssClasses,
  hubSpotTrackingCode,
}) => {
  const {
    locale,
    logo,
    headerMenu,
    footerMenus,
    footerCopy,
    cookieConsentAgreeButton,
    cookieConsentMessage,
    privacyPolicyPage,
  } = data

  const [cookies, setCookie] = useCookies()

  const {cookiePrefsSet, analytics, intercom} = cookies

  React.useEffect(() => {
    return globalHistory.listen(({location}) => {
      window['gtag']?.('config', 'UA-101017396-1', {page_path: location.pathname})
      window['gtag']?.('config', 'G-T10P89YEND', {page_path: location.pathname})
    })
  }, [])

  const handleCookiePreferenceChange = (preferences: {analytics: boolean; intercom: boolean}) => {
    const opts: CookieSetOptions = {
      path: '/',
      maxAge: 365 * 24 * 60 * 60 * 1000,
    }
    setCookie('cookiePrefsSet', '1', opts)
    Object.entries(preferences).forEach(([name, value]) => {
      setCookie(name, value ? '1' : '0', opts)
    })
  }

  return (
    <>
      <div id="page" className={cx({'is-dark': !!isDark})}>
        <Helmet>
          <html lang={locale} />
        </Helmet>
        <Helmet>
          <script async src="https://www.googletagmanager.com/gtag/js?id=UA-101017396-1" />
          <script type="text/javascript">{`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('consent', 'default', {analytics_storage: '${analytics ? 'granted' : 'denied'}'});
            gtag('config', 'UA-101017396-1', {anonymize_ip: true});
            gtag('config', 'G-T10P89YEND');
          `}</script>
        </Helmet>
        {analytics === '1' && !!hubSpotTrackingCode && (
          <Helmet>
            <script
              type="text/javascript"
              id="hs-script-loader"
              async
              defer
              src="//js.hs-scripts.com/40168420.js"></script>
          </Helmet>
        )}
        {intercom === '1' && (
          <Helmet>
            <script type="text/javascript">{`
              (function () {
                var APP_ID = "j7fuwaax";
                window.intercomSettings = {
                  app_id: APP_ID
                };
                (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
              })();
            `}</script>
          </Helmet>
        )}
        <LayoutHeader
          locale={locale}
          logo={logo}
          alternatePagePath={alternatePagePath}
          headerCssClasses={headerCssClasses}
          headerMenu={headerMenu}
        />
        <main id="main">{children}</main>
        {cookiePrefsSet !== '1' && (
          <CookieConsent
            data={{
              cookieConsentMessage,
              cookieConsentAgreeButton,
              privacyPolicyPage,
            }}
            locale={locale}
            onPreferenceChange={handleCookiePreferenceChange}
          />
        )}
        <LayoutFooter
          locale={locale}
          footerCopy={footerCopy}
          navMenu={headerMenu}
          footerLinks={footerMenus}
        />
      </div>
    </>
  )
}
