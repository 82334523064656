import * as React from 'react'
import {PictureData} from '../../Picture'
import {ScreenContentCarousel} from './ScreenContentCarousel'
import {ScreenContentImage} from './ScreenContentImage'
import cx from 'classnames'

export interface DeviceProps {
  images: PictureData[]
}

export const Device: React.FC<DeviceProps> = ({images}) => {
  return (
    <div className={cx('device', {'has-multiple-images': images.length > 1})}>
      <div className="device-inner">
        <div className="device-screen">
          {images.length > 1 ? (
            <ScreenContentCarousel images={images} />
          ) : (
            <ScreenContentImage image={images[0]} />
          )}
        </div>
      </div>
    </div>
  )
}
