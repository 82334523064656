import * as React from 'react'
import {graphql} from 'gatsby'
import type {PictureData} from '../Picture'
import {renderRichText} from '../ContentfulRichText'
import {TheJourney, JourneySegment} from '../TheJourney'

export const query = graphql`
  fragment ContentfulJourney on ContentfulJourney {
    type: __typename
    locale: node_locale
    id
    contentful_id
    title
    segments {
      id
      body {
        raw
      }
      images {
        ...Picture
      }
    }
  }
`

export interface ContentfulJourneyData {
  type: 'ContentfulJourney'
  id: string
  contentful_id: string
  locale: string
  title: string
  segments: {
    id: string
    body: {
      raw: string
    }
    images: PictureData[]
  }[]
}

export interface ContentfulJourneyProps {
  data: ContentfulJourneyData
}

export const ContentfulJourney: React.FC<ContentfulJourneyProps> = ({data}) => {
  return (
    <TheJourney>
      <div className="journey-header">
        <div className="content has-text-centered">
          <h1>{data.title}</h1>
        </div>
      </div>
      {data.segments.map(segment => (
        <JourneySegment key={segment.id} images={segment.images}>
          <div className="content is-text-large">
            {renderRichText({locale: data.locale, richText: segment.body.raw})}
          </div>
        </JourneySegment>
      ))}
    </TheJourney>
  )
}
