import * as React from 'react'
import {graphql} from 'gatsby'
import {ContentfulComponentSwitch} from '../ContentfulComponentSwitch'
import type {ContentfulCopyData} from '../ContentfulCopy'
import type {ContentfulImageGroupData} from '../ContentfulImageGroup'
import type {ContentfulColumnGroupData} from '../ContentfulColumnGroup'
import type {ContentfulCarouselData} from '../ContentfulCarousel'

export const query = graphql`
  fragment ContentfulComposition on ContentfulComposition {
    type: __typename
    id
    contentful_id
    components {
      ...ContentfulCopy
      # ...ContentfulImageGroup
      ...ContentfulColumnGroup
      ...ContentfulCarousel
    }
  }
`

export interface ContentfulCompositionData {
  type: 'ContentfulComposition'
  id: string
  contentful_id: string
  components: (
    | ContentfulCopyData
    | ContentfulImageGroupData
    | ContentfulColumnGroupData
    | ContentfulCarouselData
  )[]
}

export interface ContentfulCompositionProps {
  data: ContentfulCompositionData
}

export const ContentfulComposition: React.FC<ContentfulCompositionProps> = ({data}) => {
  const {components} = data
  return (
    <>
      {components.map(component => {
        return <ContentfulComponentSwitch data={component} key={component.id} />
      })}
    </>
  )
}
