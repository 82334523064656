import * as React from 'react'
import {Picture, PictureData} from '../Picture'
import {graphql} from 'gatsby'

export const query = graphql`
  fragment ContentfulLink on ContentfulLink {
    type: __typename
    id
    title
    url
    allowReferrer
    trackingEventName
    image {
      ...Picture
    }
  }
`

export interface ContentfulLinkData {
  type: 'ContentfulLink'
  id: string
  title: string
  url: string
  image?: PictureData
  allowReferrer?: boolean
  trackingEventName?: string
}

export interface ContentfulLinkProps {
  data: ContentfulLinkData
}

export const ContentfulLink: React.FC<ContentfulLinkProps> = ({data}) => {
  const {url, title, image, allowReferrer, trackingEventName} = data
  const rel = ['noopener']
  if (!allowReferrer) {
    rel.push('noreferrer')
  }

  const handleClick = () => {
    trackingEventName && window['gtag']?.('event', trackingEventName)
  }

  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a href={url} target="_blank" rel={rel.join(' ')} onClick={handleClick}>
      {image ? <Picture data={image} alt={title} /> : title}
    </a>
  )
}
